body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  margin-bottom: 24px;
}

.card-body {
  -webkit-box-flex: 1;
}
.btn {
  margin: 2px;
}
.list-group-item {
  margin-bottom: 2px;
  border-radius: 0.25rem;
}

/* use these below two classes to change card header colors */
/* .card-header:first-child {
  background-color: #343a40;
  color: white;
}

svg:not(:root).svg-inline--fa {
  color: white;
} */

.iconBtn {
  border: 0;
  background: transparent;
}

.requiredField::after {
  content: " *";
  color: red;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 7px;
  border-color: #eeeeee;
  border-style: solid;
  background-color: #fafafa;
  color: #4f4f4f;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.pdfCellFontColor {
  /* font-size: "40px"; */
  color: black;
}

.k-grid-header {
  font-weight: bold;
}

label {
  font-weight: bold;
}

.custom-control-label {
  font-weight: normal;
}
